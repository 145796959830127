<template>
    <v-app>
        <v-card flat class="">
            <v-card-title>
                <div class="d-flex flex justify-content-between mr-3">
                    <a
                            href="#"
                            v-if="assignment"
                            class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                    >{{assignment.title}}</a
                    >
                    <div class="my-lg-0 my-3">
                        <strong v-if="assignment.total_marks">Total Marks : {{assignment.total_marks}}</strong>
                        <strong class="ml-2" v-if="assignment.pass_marks">Pass Marks :
                            {{assignment.pass_marks}}</strong>
                    </div>
                </div>
            </v-card-title>
            <v-card-text>
                <v-card-subtitle>
                    <span v-if="assignment.course"> Course : <strong>{{ assignment.course.title || 'N/A' }} |  </strong></span>
                    <span v-if="assignment.course"> Program : <strong>{{ assignment.course.program.title|| 'N/A' }} |  </strong></span>
                    <span v-if="assignment.course"> Grade : <strong>{{ assignment.course.grade.title|| 'N/A' }} |  </strong></span>
                    <span v-if="assignment.submission_type"> Submission Type : <strong>{{ assignment.submission_type | format_status | capitalize   }} |  </strong></span>
                    <br>
                    <span v-if="assignment.enable_submission_date"> Submission Allowed Date : <strong>{{ assignment.allow_submission_date | moment("dddd, MMMM Do YYYY, h:mm:ss a") ||  'N/A' }}   </strong></span>
                    <br>
                    <span v-if="assignment.enable_due_date "> Assignment Due Date : <strong>{{ assignment.due_date | moment("dddd, MMMM Do YYYY, h:mm:ss a") ||  'N/A' }}   </strong></span>
                    <br>
                    <span v-if="assignment.enable_cut_off_date "> Cut Off Date : <strong>{{ assignment.cut_off_date | moment("dddd, MMMM Do YYYY, h:mm:ss a") ||  'N/A' }}   </strong></span>
                    <br>
                    <span v-if="assignment.enable_grading_reminder "> Grading Date : <strong>{{ assignment.grading_reminder_date | moment("dddd, MMMM Do YYYY, h:mm:ss a") ||  'N/A' }}   </strong></span>

                </v-card-subtitle>
            </v-card-text>
        </v-card>
        <template>
            <div>
                <v-expansion-panels
                        v-model="panel"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header><h4>Question/Details</h4></v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p v-html="assignment.description" class="">
                            </p>
                            <assignment-attachment :assignment_id="assignment_id"></assignment-attachment>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header><h4>Marking Criteria</h4></v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div v-if="assignment && assignment.assignment_marking_criteria ">
                                <h4>Marking Criteria</h4>
                                <p v-if="assignment" v-html="assignment.assignment_marking_criteria"
                                   class="font-weight-bold text-dark-50 ml-4">
                                </p>
                            </div>
                            <!--                  <v-data-table-->
                            <!--                      :loading="isLoading"-->
                            <!--                      :headers="headers"-->
                            <!--                      :items-per-page="itemPerPage"-->
                            <!--                      :items="assignments_submissions"-->
                            <!--                  >-->
                            <!--                    <template v-slot:item.image="{ item }">-->
                            <!--                      <div class="symbol-label">-->

                            <!--                        <img v-if="item.image" :src="item.image" class="cursor-pointer"-->
                            <!--                             alt=""-->
                            <!--                             style="height: 30px;">-->
                            <!--                        <span v-else class="symbol symbol-35 symbol-light-success">-->
                            <!--                            <span-->
                            <!--                                class="symbol-label font-size-h5 font-weight-bold cursor-pointer"-->
                            <!--                            > {{-->
                            <!--                                item.full_name.charAt(0)-->
                            <!--                              }} </span>-->
                            <!--                        </span>-->
                            <!--                      </div>-->
                            <!--                    </template>-->
                            <!--                    <template v-slot:item.full_name="{ item }">-->

                            <!--                      <router-link v-if="item.submission" :to="{ name:'assignment-submission-detail',params:{-->
                            <!--                                                                       assignment_id: item.submission.assignnment.id,-->
                            <!--                                                                        student_id:item.student_id-->
                            <!--                                                                       }}">-->
                            <!--                        <strong> {{ item.full_name }} </strong>-->
                            <!--                      </router-link>-->
                            <!--                      <strong v-else> {{ item.full_name }} </strong>-->

                            <!--                    </template>-->
                            <!--                    <template v-slot:item.submission="{ item }">-->
                            <!--                                           <span-->
                            <!--                                               :class="item.submission?'badge badge-success':'badge badge-danger'">{{-->
                            <!--                                               (item.submission && item.submission.status) ? item.submission.status : 'Not Submitted'-->
                            <!--                                             }}</span>-->
                            <!--                    </template>-->
                            <!--                    <template v-slot:item.submited_date="{ item }">-->
                            <!--                                      <span v-if="item.submission">-->
                            <!--                                          {{item.submission.submitted_at}}-->
                            <!--                                      </span>-->
                            <!--                      <span v-else>-->
                            <!--                                              N/A-->
                            <!--                                          </span>-->
                            <!--                    </template>-->
                            <!--                    <template v-slot:item.mark="{ item }">-->
                            <!--                      {{item.submission ? item.submission.mark_obtained : 'Not Submitted'}}-->
                            <!--                    </template>-->

                            <!--                  </v-data-table>-->
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </template>
        <v-row class="mt-5">
            <v-col md="3" cols="12">
                <template>
                    <v-card

                    >
                        <v-toolbar
                                color="light-grey"
                                dark
                        >

                            <v-toolbar-title>Students</v-toolbar-title>

                        </v-toolbar>

                        <v-list two-line>
                            <v-list-item-group
                                    v-model="selected"
                                    active-class="blue--text"
                            >
                                <template v-for="(item, index) in assignments_submissions">

                                    <v-list-item :key="item.title" @click="getStudentAssignment(item.id)">

                                        <template v-slot:default="{ active }">
                                            <v-list-item-content>

                                                <v-list-item-title v-text="item.full_name"></v-list-item-title>
                                                <v-list-item-subtitle
                                                        class="text--primary"
                                                >
                                            <span
                                                    :class="item.submission?'badge badge-success':'badge badge-danger'">{{
                                               (item.submission && item.submission.status) ? item.submission.status : 'Not Submitted'
                                             }}</span>
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle>
                                                      <span v-if="item.submission">
                                          {{item.submission.submitted_at}}
                                      </span>
                                                    <span v-else>
                                              N/A
                                          </span>
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle>
                                                    {{item.submission ? `Mark Obtained :  ${item.submission.mark_obtained}` : 'Not Submitted'}}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-list-item-action-text v-text="item.marking_status"></v-list-item-action-text>

                                                <v-icon
                                                        v-if="item.submission"
                                                        color="grey lighten-1"
                                                >
                                                    mdi-check
                                                </v-icon>

                                                <v-icon
                                                        v-else
                                                        color="yellow darken-3"
                                                >
                                                    mdi-cancel
                                                </v-icon>
                                            </v-list-item-action>
                                        </template>
                                    </v-list-item>

                                    <v-divider
                                            v-if="index < items.length - 1"
                                            :key="index"
                                    ></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </template>
            </v-col>
            <v-col md="9" cols="12">
                <v-card

                >
                    <v-toolbar
                            color="light-blue"
                            dark
                    >
                        <v-toolbar-title>Student Submission</v-toolbar-title>
                    </v-toolbar>
                    <div class="p-10" v-if="selectedStudent.id==null">
                        Select a student to view submission and marking
                    </div>
                    <div class="p-0" v-else>
                        <AssignmentSubmissionDetail :studentId="selectedStudent.id" :assignmentId="assignment_id"></AssignmentSubmissionDetail>
                    </div>

                </v-card>

            </v-col>
        </v-row>
    </v-app>
</template>

<script>
    import AssignmentSubmissionService from "@/core/services/assignement/AssignmentSubmissionService";
    const assignmentSubmissionService = new AssignmentSubmissionService();
    import {AssignmentMixin} from "@/mixins/AssignmentMixin";
    import AssignmentAttachment from "./AssignmentAttachment";
    import AssignmentSubmissionDetail from "./submission/SumissionDetail";
    export default {
        name: "AssignmentDetail",
        mixins: [AssignmentMixin],
        components: {AssignmentSubmissionDetail, AssignmentAttachment},
        data() {
            return {
                isBusy: false,
                itemPerPage: 20,
                page: 1,
                total: 0,
                panel: [0],
                assignments_submissions: [],
                search:{
                    name:null,
                    submission_status:null
                },
                headers: [
                    {text: '', value: 'image', filterable: true, sortable: true},
                    {text: 'Full Name', value: 'full_name', filterable: true, sortable: true},
                    {text: 'Submission Status', value: 'submission', filterable: true, sortable: true},
                    {text: 'Submitted Date', value: 'submited_date', filterable: true, sortable: true},
                    {text: 'Obtained Marks', value: 'mark', filterable: true, sortable: true},
                ],
                selectedStudent:{
                    id:null,
                    assignmentId:null
                },
                selected: null,
                items: [
                    {
                        id: 3,
                        marking_status: 'Not Marked',
                        submission_status: 'Submission Status',
                        submitted_date: `10/20/2021`,
                        full_name: 'Student Full Name',
                        mark_obtained: 'Student Full Name',
                    },
                    {
                        id: 1,
                        marking_status: 'Not Marked',
                        submission_status: 'Submission Status',
                        submitted_date: `10/20/2021`,
                        full_name: 'Student Full Name',
                        mark_obtained: 'Student Full Name',
                    },
                    {
                        id: 3,
                        marking_status: 'Not Marked',
                        submission_status: 'Submission Status',
                        submitted_date: `10/20/2021`,
                        full_name: 'Student Full Name',
                        mark_obtained: 'Student Full Name',
                    },
                    {
                        id: 3,
                        marking_status: 'Not Marked',
                        submission_status: 'Submission Status',
                        submitted_date: `10/20/2021`,
                        full_name: 'Student Full Name',
                        mark_obtained: 'Student Full Name',
                    },
                    {
                        id: 3,
                        marking_status: 'Not Marked',
                        submission_status: 'Submission Status',
                        submitted_date: `10/20/2021`,
                        full_name: 'Student Full Name',
                        mark_obtained: 'Student Full Name',
                    },
                ],
            }
        },
        computed: {
            assignment_id() {
                return this.$route.params.assignment_id;
            },
        },
        watch: {
            selected(newValue, oldValue) {
                this.selectedStudent.id =  this.assignments_submissions[newValue].student_id;
                this.selectedStudent.assignmentId =  this.assignment_id;
            }
        },
        methods: {
            getStudentAssignment(id){
                this.selectedStudent.id = id;
            },
            getAssignmentsSubmissionStudents() {
                this.isBusy = true;
                assignmentSubmissionService.paginate(this.assignment_id,this.search, this.page).then(response => {
                    this.assignments_submissions = response.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                }).catch((err) => {
                    // console.log(err)
                }).finally(() => {
                    this.isBusy = false;

                });
            },
        },
        mounted() {
            this.getSingleAssignment(this.assignment_id);
            this.getAssignmentsSubmissionStudents();
        }

    }
</script>
a
<style scoped>

</style>